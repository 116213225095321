.animationControl {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1119px;
  margin: 44px auto;
  gap: 14px;
}
.singleQuestion {
  border-radius: 17.548px;
  border: 1.097px solid rgba(255, 255, 255, 0.08);
  background: rgba(97, 136, 187, 0.11);
  padding: 28px 34px;
}
.questionSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.64519rem;
  cursor: pointer;
}

.answer {
  color: rgba(255, 255, 255, 0.4);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.64519rem;
}
