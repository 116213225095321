.extraBottomPadding {
  padding-bottom: 2.5rem;
}
.header {
  max-width: 911px;
  margin: auto;
  text-align: center;
  margin-bottom: 4rem;
}
.cardContainer {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.singleCard {
  width: 32%;
  padding: 2.25rem;
  border-radius: 1rem;
  border: 1px solid #fff;
  backdrop-filter: blur(32px);
  display: flex;
  flex-direction: column;
}

.imageContainer {
  height: 4.25rem;
  width: 4.25rem;
  border-radius: 16px;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardTitle {
  color: var(--blue-1);
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem; /* 120% */
  letter-spacing: -0.0125rem;
  margin-bottom: 1rem;
}
.cardSubTitle {
  color: rgba(25, 22, 83, 0.69);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
}

@media screen and (max-width: 1111px) {
  .singleCard {
    width: 30%;
  }
}

@media screen and (max-width: 900px) {
  .singleCard {
    width: 48%;
  }
}

@media screen and (max-width: 600px) {
  .singleCard {
    width: 99%;
  }
  .cardTitle {
    font-size: 16px;
  }
  .cardSubTitle {
    font-size: 14px;
  }
}
