.navbar {
  width: 100%;
  background: transparent;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 109;
}

.links {
  display: flex;
  align-items: center;
  gap: 4px;
}

.link {
  padding: 6px 18px;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background-color: transparent;
  border-radius: 60px;
  transition: background-color 0.2s;
  cursor: pointer;
}
.link:hover {
  background-color: var(--white-10);
}

.subLink {
  padding: 6px 18px;
  font-size: 14px;
  color: #000;
  display: inline-block;
  font-weight: 400;
  width: max-content;
  line-height: 20px;
  background-color: transparent;
  border-radius: 60px;
  transition: background-color 0.2s;
  cursor: pointer;
  margin-bottom: 2.4px;
}

.subLink:hover {
  background-color: rgba(17, 17, 17, 0.05);
}

.dropdownGroup {
  display: flex;
  align-items: center;
  gap: 4px;
  position: relative;
  z-index: 200;
}

.dropdownNav {
  padding-top: 0.5rem;
  min-width: 225px;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown {
  background-color: var(--white);
  border-radius: 1.25rem;
  padding: 18px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdownGroup:hover .dropdownNav {
  display: block;
}

.dropdownHeading {
  margin-left: 18px;
  text-transform: uppercase;
  font-weight: 600;
  background-image: var(--gradient-1);
  background-size: 100%;
  margin-bottom: 6px;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
}

.dropdownLinks {
  display: flex;
  flex-direction: column;
  gap: 2px;
  position: relative;
  z-index: 200;
}

.actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.hamburger {
  display: none;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;
}

.line {
  width: 24px;
  height: 2px;
  background-color: var(--white);
  border-radius: 2px;
  transition: transform 0.2s;
}

.hamburger.active {
  gap: 6px;
}

.hamburger.active .topLine {
  transform: translate3d(0px, 8px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(45deg)
    skew(0deg);
  transform-style: preserve-3d;
}

.hamburger.active .middleLine {
  opacity: 0;
}

.hamburger.active .bottomLine {
  transform: translate3d(0px, -8px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg)
    rotateZ(-45deg) skew(0deg);
  transform-style: preserve-3d;
}
.mobileNavbar {
  display: none;
  position: absolute;
  z-index: 200000;
  width: 100%;
  height: 0;
  /* top: 100%; */
  top: 100px;
  left: 0;
  background-color: var(--white);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: height 0.2s;
  overflow: hidden;
  /* padding: 0; */
  margin: 0;
  /* padding: 2rem 1rem; */
}
.mobileNavbar.open {
  height: 100vh;
  width: 100vw;
  z-index: 200000;
}

.mobileLinks {
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin: 2rem 1rem;
}

.mobileLink {
  padding: 12px 16px;
  font-size: 20px;
  color: #000;
  font-weight: 500;
  width: max-content;
  background-color: transparent;
  border-radius: 60px;
  transition: background-color 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
}

.mobileLink:hover {
  background-color: rgba(17, 17, 17, 0.05);
}

.mobileDropdownNav {
  padding-top: 16px;
  margin-left: 24px;
  display: none;
}

.mobileDropdownGroup:hover .mobileDropdownNav {
  display: block;
}

.mobileDropdown .subLink {
  font-size: 18px;
  padding: 12px 15px;
  margin-bottom: 0;
}

.mobileDropdownLinks {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media screen and (max-width: 990px) {
  .navbar {
    /* width: var(--container-responsive-width); */
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }

  .mobileNavbar {
    display: block;
  }

  .links {
    display: none;
  }

  .hamburger {
    display: flex;
  }
}

.companyLogo {
  height: 45px;
  width: auto;
}

@media only screen and (max-width: 800px) {
  .navbar {
    width: var(--container-responsive-width);
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 500px) {
  .companyLogo {
    height: 35px;
    width: auto;
  }
}

@media only screen and (max-width: 400px) {
  .companyLogo {
    height: 35px;
    width: auto;
  }
}

@media only screen and (max-width: 350px) {
  .companyLogo {
    height: 30px;
    width: auto;
  }
}
