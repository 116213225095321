.heading {
  max-width: 75%;
  width: 100%;
}
.topLeftText {
  width: 50%;
  max-width: 521px;
  position: relative;
}
.topLine {
  position: absolute;
  right: -31%;
  bottom: -3%;
  max-width: 148px;
}
.colorP50 {
  color: var(--p-50);
}
.topParaTextContainer {
  margin-top: 32px;
  width: 100%;
}
.topParaText {
  position: relative;
  display: inline-block;
  line-height: 1.3;
}

.topParaText::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, white 50%, #d7f0e5 50%);
  z-index: -1;
}

@media screen  and (max-width: 1068px){
  .topParaText::before {
    background: none;
  }
  .topLine {
    display: none;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  .topLeftText {
    flex-shrink: 0;
  }
}

@media screen and (max-width: 800px){
  .topLine {
    display: none;
  }
  .topParaTextContainer {
    margin-top: 24px;
    width: 100%;
  }
}

@media screen and (max-width: 650px){
  .topParaText {
    display: inline;
  }
 .topLeftText {
  width: 100%;
 }
}
