.navbar {
  width: 100%;
  background: var(--white);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  box-shadow: 8px 12px 24px 0px #12121214;
  border-bottom: 1px solid var(--stroke-tabs-sections);
}
.navbarInnerContainer {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  box-shadow: 8px 12px 24px 0px #12121214;
}
.links {
  display: flex;
  align-items: center;
}
.link {
  padding: 6px 12px;
  /* color: var(--white); */
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background-color: transparent;
  border-radius: 60px;
  transition: background-color 0.2s;
  cursor: pointer;
}
.link:hover {
  cursor: pointer;
}

.subLink {
  padding: 6px 18px;
  font-size: 14px;
  color: #000;
  display: inline-block;
  font-weight: 400;
  width: max-content;
  line-height: 20px;
  background-color: transparent;
  border-radius: 60px;
  transition: background-color 0.2s;
  cursor: pointer;
  margin-bottom: 2.4px;
}

.subLink:hover {
  background-color: rgba(17, 17, 17, 0.05);
}

.dropdownGroup {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 25;
  cursor: unset;
  gap: 4px;
}
.dropdownGroup:hover {
  color: var(--p-50);
}

.activeNavText {
  color: var(--p-50);
}
.activeNavText .downArrow {
  transform: rotate(180deg);
}

.actionWevBtnContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}
.actionMobBtnContainer {
  display: none;
}

.btnIconBox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  width: 24px;
  height: 24px;
  border-radius: 4px;
}
.customBtn {
  border-radius: 8px;
  background-color: var(--p-10);
  color: var(--p-50);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-18);
  padding: 9px 12px;
}
.navleftBoxContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.hamburger {
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  outline: none;
  height: 32px;
  width: 32px;
}

.hamburger.active {
  gap: 6px;
}

.mobileNavbar {
  display: none;
  position: fixed;
  z-index: 25;
  width: 100%;
  height: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: width 0.3s;
  overflow: auto;
  margin: 0;
}
.mobileNavbarInner {
  background-color: var(--white);
  padding: 12px 16px;
  width: calc(100vw - 24px);
  height: 100vh;
  overflow: scroll;
}
.mobileNavbar.open {
  height: 100vh;
  width: 100vw;
  z-index: 25;
}
.hamburgerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.hamburgerBtnContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}
.mobileLinks {
  display: flex;
  flex-direction: column;
  /* gap: 18px; */
  padding: 28px 0;
}

.mobileDropdownGroup {
  display: flex;
  flex-direction: column;
}

.mobileDropdownGroup:hover .mobileDropdownNav {
  display: flex;
}
.customButton {
  background-color: var(--p-10);
  color: var(--p-50);
}
.activeText {
  color: var(--p-50);
}

@media screen and (max-width: 1284px) {
  .navbarInnerContainer {
    max-width: 90%;
  }
}

@media screen and (max-width: 990px) {
  .navbar {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }

  .mobileNavbar {
    display: block;
  }

  .links {
    display: none;
  }

  .link {
    font-size: 15px;
    padding: 0px 12px;
  }
  .hamburger {
    display: flex;
  }
  .mobileDropdown {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid var(--stroke-tabs-sections);
  }

  .mobileDropdown .subLink {
    font-size: 18px;
    padding: 12px 15px;
    margin-bottom: 0;
  }

  .mobileDropdownLinks {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.companyLogo {
  display: flex;
  align-items: center;
  gap: 10px;
}

.loginDropdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  border-radius: 8px;
  background: var(--white);
  box-shadow: 12px 8px 16px 0px rgba(18, 18, 18, 0.08), 0px 1px 2px 0px rgba(18, 18, 18, 0.04);
  position: absolute;
  top: 100%;
}

.loginOptions {
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--text-primary);
  font-size: 14px;
  font-style: normal;
  font-weight: 550;
  line-height: 18px;
  letter-spacing: -0.2px;
  padding: 8px 56px 8px 11px;
}

.loginOptions:hover {
  background-color: var(--fill-selection-only);
}

.adminLogin {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.navActions {
  display: initial;
}

.mediumLogin {
  display: none;
}

.smallLogin {
  display: none;
}

@media only screen and (max-width: 800px) {
  .navbarInnerContainer {
    max-width: 100%;
    height: 56px;
    padding: 12px 16px;
  }
  .navbar {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  .actionMobBtnContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .actionWevBtnContainer {
    display: none;
  }
  .navActions {
    display: flex;
    gap: 8px;
  }
  .mediumLogin {
    display: block;
  }
}

@media only screen and (max-width: 500px) {
  .companyLogo {
    width: auto;
  }
  .smallLogin {
    display: block;
  }
  .mediumLogin {
    display: none;
  }
}
