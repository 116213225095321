.container {
  width: 100%;
  padding: 7.5rem 0;
}

.subContainer {
  max-width: var(--container-width-primary);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media screen and (max-width: 1400px) {
  .subContainer {
    width: var(--container-responsive-width);
  }
}
