.heading {
  max-width: 80%;
  width: 100%;
}
.topLeftText {
  width: 50%;
  max-width: 521px;
  position: relative;
}
.h2NextLine {
  display: block;
}
.topLine {
  width: 30%;
  position: absolute;
  right: -30%;
  bottom: 6%;
}
.colorP50 {
  color: var(--p-50);
}
.topParaTextContainer {
  margin-top: 32px;
}
.topParaText {
  position: relative;
  display: inline-block;
  line-height: 1.3;
}

.topParaText::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, white 50%, #d7f0e5 50%);
  z-index: -1;
}


@media (min-width: 800px) and (max-width: 1200px) {
  .topLeftText {
    flex-shrink: 0;
  }
}

@media screen and (max-width: 800px){
  .heading {
    max-width: 100%;
  }
  .topLine {
    display: none;
  }
  .topParaTextContainer {
    margin-top: 24px;
    width: 100%;
  }
  .h2NextLine {
    display: inline-block;
  }
}

@media screen and (max-width: 650px){
 .topLeftText {
  width: 100%;
 }
}

@media (min-width: 650px) and (max-width: 1102px){
  .topParaText::before {
    background: none;
  }
}

@media screen and (max-width: 450px){
  .topParaText::before {
    background: none;
  }
}
